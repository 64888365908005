export const SectionTitle = {
  mb: { xs: 5, sm: 8 },
  mx: 'auto',
  px: { xs: 4 },
  maxWidth: { xs: '920px', xl: '1100px' },
  textAlign: 'center',
};

export const SectionWrapper = {
  px: { xs: 0, sm: 4, md: 8, lg: 14, xl: 27 },
  py: { xs: 5, md: 8 },
};

export const ImageWrapper = {
  height: { xs: '340px', sm: '380px', lg: '440px' },
  position: 'relative',
  textAlign: 'center',
  width: { xs: '100%', md: '50%' },
};

export const TextWrapper = {
  backgroundColor: (theme) => theme.palette.productsColor.abroad,
  color: 'white',
  display: 'block',
  height: { xs: '360px', sm: '380px', lg: '440px' },
  padding: { xs: 4, lg: 8, xl: 10 },
  width: { xs: '100%', md: '50%' },
};

export const Paragraph = {
  maxWidth: { xs: '500px', xl: '600px' },
  mb: { xs: 3, lg: 6 },
};
